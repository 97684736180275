<template>
    <section class="scientific-technical">
        <div class="expertise d-flex flex-column flex-lg-row align-items-center py-5">
            <div class="col-12 col-lg-6 d-flex justify-content-center">
                <img
                    class="scientific-technical__img"
                    src="../../assets/views/project_expertise/s-img.svg"
                    alt=""
                    width="502"
                    height="296"
                >
            </div>

            <div class="col-12 col-lg-6 d-flex flex-column align-items-center align-items-lg-start mt-4 mt-lg-0">
                <div class="expertise__title mx-3">{{ getScientificPageInfo('title') }}</div>
                <div class="expertise__text mt-4 mx-3 me-lg-4">
                    {{ getScientificPageInfo('text') }}
                </div>
            </div>
        </div>

        <div class="expertise-directions">
            <div class="expertise-directions__text my-5">{{ getScientificPageInfo('subtitle') }}</div>

            <div class="d-flex flex-column flex-md-row flex-wrap justify-content-between">
                <div class="d-flex justify-content-center d-flex justify-content-xxl-start col-12 col-lg-6 col-xxl-3 mt-5 mt-xxl-0">
                        <div class="expertise-directions-card position-relative">
                        <img src="../../assets/views/project_expertise/Card1.png" alt="" width="320" height="186">
                        <div class="position-absolute expertise-directions-card__text">
                            {{ getDirectionDescription(1) }}
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-center d-flex justify-content-xxl-start col-12 col-lg-6 col-xxl-3 mt-5 mt-xxl-0">
                    <div class="expertise-directions-card position-relative">
                        <img src="../../assets/views/project_expertise/Card2.png" alt="" width="320" height="186">
                        <div class="position-absolute expertise-directions-card__text">
                            {{ getDirectionDescription(2) }}
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-center d-flex justify-content-xxl-start col-12 col-lg-6 mt-lg-5 mt-xxl-0 col-xxl-3 mt-5 mt-xxl-0">
                    <div class="expertise-directions-card position-relative">
                        <img src="../../assets/views/project_expertise/Card3.png" alt="" width="320" height="186">
                        <div class="position-absolute expertise-directions-card__text">
                            {{ getDirectionDescription(3) }}
                        </div>
                    </div>
                </div>

                <div
                    class="d-flex justify-content-center d-flex justify-content-xxl-start col-12 col-lg-6 mt-lg-5 mt-xxl-0 col-xxl-3 mt-5 mt-xxl-0">
                    <div class="expertise-directions-card position-relative">
                        <img src="../../assets/views/project_expertise/Card4.png" alt="" width="320" height="156">
                        <div class="position-absolute expertise-directions-card__text">
                            {{ getDirectionDescription(4) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { useStore } from "vuex";
import {computed} from "vue";

export default {
    name: "ScientificTechnical",

    setup() {
        const store = useStore()

        store.dispatch('pages/getProjectsExpertisePage')
        const projectsExpertisePage = computed(() => store.getters['pages/projectsExpertisePage'])

        const getDirectionDescription = (order) => {
            if (projectsExpertisePage.value) {
                return projectsExpertisePage.value.tabs_widget.expertise_directions.find(item => item.order ===
                    order).description
            }
        }

        const getScientificPageInfo = (key) => {
            if (projectsExpertisePage.value) {
                return projectsExpertisePage.value.tabs_widget.second_tab[key]
            }

            return ''
        }

        return {
            getDirectionDescription,
            getScientificPageInfo
        }
    }
}
</script>
